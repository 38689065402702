



























































































































import { Component } from "vue-property-decorator";
import BaseView from "@/views/BaseView.vue";
import CreateForm from "./forms/CreateProductFrom.vue";
import EditForm from "./forms/EditProductForm.vue";

import ProductApi from "@/apis/product/ProductApi";
import Pagenation from "@/models/Pagenation";
import ProductItems, {
  ProductCategoryItem,
} from "@/views/models/admin/ProductItems";

@Component({
  components: {
    CreateForm,
    EditForm,
  },
})
export default class Main extends BaseView {
  private Headers = [
    { text: "ID", value: "id" },
    {
      text: "카테고리",
      value: "product_category.name",
      sortable: false,
    },
    {
      text: "판매유형",
      value: "product_category.type",
    },
    {
      text: "이름",
      value: "name",
      sortable: false,
    },
    {
      text: "모델명",
      value: "model_name",
      sortable: false,
    },
    {
      text: "사용유무",
      value: "enabled",
    },
    { text: "설정", value: "actions", sortable: false },
  ];

  private isCreating = false;
  private isEditing = false;
  private editedItem!: ProductItems;

  private ProductApi: ProductApi = new ProductApi();
  private pagenation = new Pagenation();
  private items: ProductItems[] = [];
  private productTags: ProductCategoryItem[] = [];
  private categoryBeforeIdx!: number;

  protected params = {
    per_page: 10,
    page: 1,
    category_id: 0,
  };

  protected created() {
    this.loadDatas(1);
  }

  private async loadDatas(page: number) {
    try {
      this.params.page = page;
      const repo = await this.ProductApi.getProducts(this.params);
      repo.data.product.per_page = Number(repo.data.product.per_page);
      this.productTags = [...repo.data.categoryGroups];
      this.pagenation = repo.data.product;
      this.items = [...repo.data.product.data];
    } catch (e) {
      this.handleError(e);
    }
  }

  private onClickTag(data: number) {
    this.params.category_id = data;
    this.loadDatas(1);
  }

  private onClickCreated() {
    this.isCreating = true;
  }

  private onClickEdit(item: ProductItems) {
    this.isEditing = true;
    this.editedItem = { ...item };
    this.categoryBeforeIdx = item.product_category.id;
  }

  private onAddItem(requestItem: ProductItems) {
    this.isCreating = false;
    this.items.unshift(requestItem);
  }

  private onEditItem(requestItem: ProductItems) {
    this.isEditing = false;
    const items = [...this.items];
    const itemIdx = items.findIndex((item) => item.id === requestItem.id);
    items[itemIdx] = requestItem;
    this.items = items;

    const tags = [...this.productTags];
    const beforeIdx = tags.findIndex(
      (item) => item.id === this.categoryBeforeIdx
    );
    const afterIdx = tags.findIndex(
      (item) => item.id === requestItem.product_category_id
    );
    tags[beforeIdx].product_count--;
    tags[afterIdx].product_count++;
    this.productTags = tags;

    this.$showSnack("카테고리가 수정되었습니다.");
  }

  private onClickDelete(id: number) {
    this.$confirm("알림", "삭제하시겠습니까?", async (result: boolean) => {
      if (!result) return;
      try {
        this.$showProgress();
        await this.ProductApi.deleteProduct(id);
        const items = [...this.items];
        const idx = items.findIndex((item) => item.id === id);
        const tags = [...this.productTags];
        const tagIdx = tags.findIndex(
          (item) => item.id === items[idx].product_category_id
        );
        tags[tagIdx].product_count--;
        this.productTags = tags;

        items.splice(idx, 1);
        this.items = items;
        this.$showSnack("해당 카테고리가 삭제되었습니다.");
        this.$hideProgress();
      } catch (e) {
        this.handleError(e);
      }
    });
  }
}
