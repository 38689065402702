
































































import ProductApi from "@/apis/product/ProductApi";
import BaseForm from "@/components/dialog/BaseForm.vue";
import ValidationSelect from "@/components/ui/ValidationSelect.vue";
import ValidationTextarea from "@/components/ui/ValidationTextarea.vue";
import ValidationTextField from "@/components/ui/ValidationTextField.vue";
import BaseView from "@/views/BaseView.vue";
import { Component, Emit, Prop } from "vue-property-decorator";
import ProductCategoryStates from "@/consts/ProductCategoryStates";
import ProductItems, {
  ProductCategoryItem,
} from "@/views/models/admin/ProductItems";
import { ValidationObserver } from "vee-validate";

@Component({
  components: {
    BaseForm,
    ValidationTextField,
    ValidationSelect,
    ValidationTextarea,
  },
})
export default class EditForm extends BaseView {
  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
    productModelSelect: InstanceType<typeof ValidationSelect>;
  };
  @Emit()
  private cancel() {
    return false;
  }

  @Emit()
  private submit(data: ProductItems) {
    return data;
  }

  @Prop() private editedItem!: ProductItems;
  @Prop() private productTags!: ProductCategoryItem[];

  private productApi: ProductApi = new ProductApi();
  private categoryItem: ProductCategoryItem[] = [];
  private states = ProductCategoryStates;

  protected created(type = this.editedItem.product_category.type) {
    this.categoryItem = this.productTags.filter((item) => item.type == type);
  }

  private async save() {
    const result = await this.$refs.observer.validate();
    if (result) {
      this.isConnecting = true;
      try {
        const repo = await this.productApi.updateProduct(this.editedItem);

        this.submit(repo.data);
      } catch (e) {
        this.handleError(e);
      }
    }
  }
  private onSelectCategory(type: string) {
    this.editedItem.product_category_id = undefined;
    this.$refs.productModelSelect.onChangeValue("");
    this.categoryItem = this.productTags.filter((item) => item.type == type);
  }
}
