
































































import { Component, Emit, Prop } from "vue-property-decorator";
import BaseView from "@/views/BaseView.vue";
import BaseForm from "@/components/dialog/BaseForm.vue";
import ValidationTextField from "@/components/ui/ValidationTextField.vue";
import ValidationSelect from "@/components/ui/ValidationSelect.vue";
import ValidationTextarea from "@/components/ui/ValidationTextarea.vue";
import ProductApi, {
  ProductCreateRequestParams,
} from "@/apis/product/ProductApi";
import ProductCategoryStates from "@/consts/ProductCategoryStates";
import ProductItems, {
  ProductCategoryItem,
} from "@/views/models/admin/ProductItems";
import { ValidationObserver } from "vee-validate";

@Component({
  components: {
    BaseForm,
    ValidationTextField,
    ValidationSelect,
    ValidationTextarea,
  },
})
export default class CreateForm extends BaseView {
  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
    productModelSelect: InstanceType<typeof ValidationSelect>;
  };

  @Emit()
  private cancel() {
    return false;
  }

  @Emit()
  private submit(data: ProductItems) {
    return data;
  }

  private productApi: ProductApi = new ProductApi();
  private categoryItem: ProductCategoryItem[] = [];
  private states = ProductCategoryStates;
  private params!: ProductCreateRequestParams;

  @Prop() private productTags!: ProductCategoryItem[];

  private created() {
    this.params = { ...this.params };
  }

  private async save() {
    const result = await this.$refs.observer.validate();
    if (result) {
      this.isConnecting = true;
      try {
        const repo = await this.productApi.createProduct(this.params);
        this.submit(repo.data);
      } catch (e) {
        this.handleError(e);
      }
    }
  }
  private onSelectCategory(type: string) {
    this.params.product_category_id = undefined;
    this.$refs.productModelSelect.onChangeValue("");
    this.categoryItem = this.productTags.filter((item) => item.type == type);
  }
}
